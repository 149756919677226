<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y:scroll">
		<div class="dF aC">
            <h4 class="mb-3 mt-3">Events</h4>
			<div v-if="selectedRowKeys.length && selectedRowKeys.length > 0" class="dF aC">
				<div class="ml-3" style="color:var(--orange)">{{`${selectedRowKeys.length} selected`}}</div>
				<div @click="bulkDelete" class="py-1 px-3 ml-3 bulk-action-button text-center" style="background-color:white; border:1px solid #ECEBED; border-radius:4px; cursor:pointer;">Delete</div>
			</div>
        </div>
        <template v-if="events.length">
            <a-table :pagination="false" :title="null" @change="handleChange" :row-selection="rowSelection" :rowKey="(e) => e.id" class="white-table" :scroll="{ x: 1000 }" :columns="columns" :dataSource="events">
                <div slot="name" slot-scope="obj">{{obj.title}}</div>
				<div slot="assignTo" slot-scope="obj">
					{{ getUser(obj.assignTo) }}
				</div>
                <div slot="preferredEventType" slot-scope="obj">
                    <div v-if="obj && obj.meetingSettings && obj.meetingSettings.preferredEventType && obj.meetingSettings.preferredEventType != ''">{{getType(obj.meetingSettings.preferredEventType)}}</div>
                </div>
                <div slot="location" slot-scope="obj">{{obj.meetingSettings && obj.meetingSettings.location ? obj.meetingSettings.location : 'N/A'}}</div>
                <div slot="reminder" slot-scope="obj">{{obj.reminderOn && reminder[obj.reminderOn] ? reminder[obj.reminderOn] : ''}}</div>
                <div slot="actions" slot-scope="obj" class="dF" style="justify-content: flex-end;">
                    <a-popover trigger="click" placement="bottomRight" overlayClassName="popoverStyle">
                        <div slot="content">
                            <div @click="previewEvent(obj)" class="popoverContent dF aC"><a-icon type="eye" class='mr-2' /><div>View Event</div></div>
                            <div @click="editEvent(obj)" class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg><div>Edit Event</div></div>
                            <!-- <div class="popoverContent dF aC"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="mr-2 feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg><div>Copy Event</div></div> -->
                            <div @click="deleteEvent(obj)" class="popoverContent dF aC"><svg style="color:#FD647C" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg><div>Delete Event</div></div>
                        </div>
                        <div class="more-option-icon mr-4">
                            <a-icon style="line-height: 40px;" type="more" />
                        </div>
                    </a-popover>
                </div>
            </a-table>
        </template>
        <template v-else>
            <EmptyApp :text="'You don\'t have any events'" :img="require('@/assets/emptyIcons/appointments.png')" />
        </template>
    </div>
</template>

<script>
import EmptyApp from 'bh-mod/components/common/EmptyApp'
import EditAppointmentModal from '@/components/contacts/EditAppointmentModal'
import moment from 'moment'
import 'moment-timezone';
export default {
    components:{
        EmptyApp,EditAppointmentModal
    },
    computed:{
		users(){
			return this.$store.state.contacts.users
		},
		timezone(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeZone && typeof this.$store.state.contacts.allSettings.user.options.regional.timeZone == 'string' ? this.$store.state.contacts.allSettings.user.options.regional.timeZone : 'America/New_York'
		},
		timeFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.timeFormat ? this.$store.state.contacts.allSettings.user.options.regional.timeFormat : 12
		},
		dateFormat(){
			return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
        events() {
            let events = this.$store.state.contacts.events || []
            if (this.sortItem.key && this.sortItem.order && this.sortItem.keys != '') {
                if (this.sortItem.key == 'date') {
                    if (this.sortItem.order == 'ascend') {
                        events = events.sort((a,b) => {
                            if (a.date > b.date) return 1
                            if (a.date < b.date) return -1
                            else return 0
                        })
                    } else if (this.sortItem.order == 'descend') {
                        events = events.sort((a,b) => {
                            if (a.date > b.date) return -1
                            if (a.date < b.date) return 1
                            else return 0
                        })
                    }
                }
            }
            return events
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: false,
                onSelection: this.onSelection,
            };
        },
        eventTypes() {
            return [
				{ name: 'Virtual Event', id: 'virtual' },
				{ name: 'Physical Event', id: 'physical' }
			]
        },
    },
    data() {
        return{
            sortItem:{},
            columns:[
                { title: 'Title', key: 'name', scopedSlots: { customRender: 'name' },},
                { title: 'Assigned to', key: 'assignTo', scopedSlots: { customRender: 'assignTo' },},
                { title: 'Event Type', key: 'preferredEventType', scopedSlots: { customRender: 'preferredEventType' },},
                { title: 'Location', key: 'location', scopedSlots: { customRender: 'location' },},
                { title: 'Reminder', key: 'reminder', scopedSlots: { customRender: 'reminder' },},
                { title: '', key: 'actions', scopedSlots: { customRender: 'actions' },},
            ],
            selectedRowKeys:[],
            reminder : {
                15:'15 Minutes',
            	30:'30 Minutes',
                60:'1 Hour',
                120:'2 Hours',
                360:'6 Hours',
                720:'12 Hours',
                1440:'1 Day',
                2880:'2 Days',
                10080:'1 Week'
            }
        }
    },
    methods:{
		moment,
		offsetDiffMs(type){
			let desiredTimezone = moment.tz(this.timezone);
			let desiredOffset = desiredTimezone.utcOffset();
			let currentOffset = new Date().getTimezoneOffset() * -1;
			let offsetDiff = 0
			let offsetDiffMs = 0
			if (currentOffset !== desiredOffset){
				if (type == 'set'){
					offsetDiff = currentOffset - desiredOffset;
				} else {
					offsetDiff = desiredOffset - currentOffset;
				}
				offsetDiffMs = offsetDiff * 60 * 1000;
			}
			return offsetDiffMs
		},
        deleteEvent(obj) {
            if (this.$p < 15 && (!obj.createdBy || obj.createdBy.id !== this.$store.state.user.user.id)){
                return this.$message.error('You do not have permission to delete this event')
            }
            let self = this
            this.$confirm({
                title: 'Delete Event',
                content: h => <div>Do you want to delete this event?</div>,
                okText: 'DELETE',
                okType: 'danger',
                cancelText: 'CANCEL',
                centered: true,
                onOk(){
                    self.$api.delete(`/event-groups/:instance/${obj.id}`).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        self.$store.commit('DELETE_EVENT',data)
                    })
                },
                onCancel(){
                    console.log('CANCELED')
                }
            })
        },
        previewEvent(item) {
            if (item && item.id) {
				this.$router.push(`/events/${item.id}`)
            }
        },
		editEvent(item) {
			console.log('Editing event...', item)
			if (this.$p < 15 && (!item.createdBy || item.createdBy.id !== this.$store.state.user.user.id)){
                return this.$message.error('You do not have permission to edit this event')
            }
			this.$store.commit("EDIT_EVENT", item)
			this.$store.commit("ADD_NEW", "Event");
		},
        handleChange(pagination, filter, sorter) {
            if (!sorter.order) this.sortItem = {}
            else if(sorter.columnKey) {
                this.sortItem = {
                    key:sorter.columnKey,
                    order:sorter.order
                }
            }
            console.log('sort item', this.sortItem)
        },
		getUser(userId){
			if (typeof userId === 'string'){
				let find = this.users.find(x => {
					if (x.id && x.id == userId) return x
				})
				if (find) return find.firstName + ' ' + find.lastName
				return 'N/A'
			} else {
				return userId.firstName + ' ' + userId.lastName
			}

		},
        getType(typeId) {
			console.log('TYPE ID', typeId)
            let find = this.eventTypes.find(x => {
                if (x.id && x.id == typeId) return x
                else if (x.value && x.value == typeId) return x
            })
            if (find) return find.name
        },
        onSelectChange(selectedRowKeys) {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        convertDate(num){
            let x = new Date(num)
            //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			x.setTime(x.getTime() + this.offsetDiffMs('get'));
            let year = x.getFullYear()
			let month = x.getMonth()+1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
			else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
			else {
				return month+'/'+day+'/'+year
			}
        },
        convertTime(time){
            let x = new Date(time)
			x.setTime(x.getTime() + this.offsetDiffMs('get'));
            let hours = x.getHours()
            let minutes = x.getMinutes()
			minutes = minutes < 10 ? '0'+minutes : minutes

			let strTime = hours + ':' + minutes

			if (this.timeFormat == 12){
				let ampm = hours >= 12 ? 'PM' : 'AM'
				hours = hours % 12
				hours = hours ? hours : 12 // the hour '0' should be '12'
				strTime = hours + ':' + minutes + ' ' + ampm
			}

            return strTime
        },
		bulkDelete() {
			if (this.$p < 40){
				return this.$message.error('You do not have permission to delete events')
			}
			let self = this;
			this.$confirm({
				title: 'Delete Events',
				content: h => <div>Do you want to delete the selected events? All information related to them will be lost.</div>,
				okText: 'DELETE',
				okType: 'danger',
				cancelText: 'CANCEL',
				centered: true,
				onOk(){
					self.$api.put(`/events/:instance/bulk-delete`, {ids: self.selectedRowKeys}).then(({data}) => {
						if(data.success) {
							self.$store.commit('BULK_DELETE_EVENT', self.selectedRowKeys)
							self.selectedRowKeys = []
						}
					}).catch((error) => {
						console.log('Error', error);
					})
				}
			})
		},
    },
    created() {
        this.$store.commit('SIDEBAR_SELECTION',{value:'Events'})
		this.$store.dispatch('DO_TAGS')
		// if (this.$route.query && this.$route.query.id) {
		// 	const event = this.events.find(a => a.id === this.$route.query.id)
		// 	if (event) {
		// 		setTimeout(() => {
		// 			this.$store.commit('OPEN_PREVIEW_MODAL', {type:'event', object:event})
		// 		}, 0)
		// 	}
		// }
    },
}
</script>

<style scoped>
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
        color: var(--orange) !important;
        cursor: pointer;
        background-color: var(--light-gray);
    }    .popoverContent{
        height: 35px;
        /* width: 160px; */
        line-height: 35px;
        padding: 0 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
        color:black;
    }
</style>
